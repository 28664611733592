export const SOME_FUTURE_DATE = "12/25/2099";

export const HALLIDAY_WATKINS: string = "HALLIDAY_WATKINS";
export const ALDRIDGE_PITE: string = "ALDRIDGE_PITE";

export const HALLIDAY_WATKINS_SALE_URL =
    "http://www.halliday-watkins.com/live/UT/bids.htm";
export const HALLIDAY_WATKINS_SOLD_URL =
    "http://www.halliday-watkins.com/live/UT/results.htm";

export const ALDRIDGE_PITE_SALE_URL =
    "https://aldridgepite.com/sale-day-listings-selection/foreclosure-listings-utah/";
export const ALDRIDGE_PITE_SALE_URL_REFERER =
    "https://aldridgepite.com/disclaimer-utah/";

export const SOURCE_URLS = {
    [HALLIDAY_WATKINS]: HALLIDAY_WATKINS_SALE_URL,
    [ALDRIDGE_PITE]: ALDRIDGE_PITE_SALE_URL,
};
