import React, { Component } from "react";
import { IFeedProps } from "../interfaces/component-props";
import UpdatesFeed from "./UpdatesFeed";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import PropertyModel from "../models/PropertyModel";
import { withRouter } from "react-router-dom";

@observer
class Feed extends Component<IFeedProps, any> {
    static propTypes = {
        propertyStore: PropTypes.object.isRequired,
    };

    onSelectProperty(property: PropertyModel) {
        this.props.history.push(`/property/${property.id}`);
    }

    componentDidMount() {
        this.props.propertyStore.fetchAllJournalEntries();
    }

    render() {
        const { propertyStore } = this.props;

        return (
            <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Feed</h1>
                </div>
                <UpdatesFeed
                    journalEntries={propertyStore.journalEntryStore.entries}
                    onClickProperty={(property) =>
                        this.onSelectProperty(property)
                    }
                />
            </div>
        );
    }
}

export default withRouter(Feed);
