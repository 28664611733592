import { action, flow, observable } from "mobx";
import Fiyabase from "../Fiyabase";
import JournalEntryModel from "../models/JournalEntryModel";
import { sub } from "date-fns";
import {
    getFirestore,
    collection,
    query,
    where,
    orderBy,
    getDocs,
} from "firebase/firestore";

const db = getFirestore(Fiyabase());

export default class JournalEntryStore {
    @observable entries: Array<JournalEntryModel> = [];

    @action
    fetchJournalEntries = flow(function* () {
        const self = this;

        const oneWeekAgo = sub(new Date(), {
            days: 7,
        });

        try {
            const entries = yield getDocs(
                query(
                    collection(db, "journalentries"),
                    where("updateDate", ">", oneWeekAgo),
                    orderBy("updateDate", "desc"),
                ),
            );

            self.entries = entries.docs.map(
                (entry) => new JournalEntryModel(this, entry.data()),
            );
        } catch (err) {
            console.log("ERR", err);
        }
    });

    toJS() {
        // return this.properties.map(todo => todo.toJS());
    }

    static fromJS(array) {}
}
