import "./App.css";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { IAppProps } from "./interfaces/component-props";
import { observer } from "mobx-react";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import Home from "./components/Home";
import Navbar from "react-bootstrap/Navbar";
import Detail from "./components/Detail";
import Nav from "react-bootstrap/Nav";
import Feed from "./components/Feed";

@observer
class App extends Component<IAppProps, any> {
    static propTypes = {
        propertyStore: PropTypes.object.isRequired,
        authStore: PropTypes.object.isRequired,
        favoriteStore: PropTypes.object.isRequired,
    };

    render() {
        const { propertyStore, authStore, favoriteStore } = this.props;

        return (
            <div className="App">
                <Router>
                    <Navbar bg="dark" variant="dark">
                        <Link to="/">
                            <Navbar.Brand>Real Estate King</Navbar.Brand>
                        </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Link className="nav-link" role="button" to="/">
                                    Properties List
                                </Link>
                                <Link
                                    className="nav-link"
                                    role="button"
                                    to="/feed"
                                >
                                    Feed
                                </Link>
                            </Nav>
                            <Navbar.Text className="justify-content-end d-none d-lg-block">
                                <a
                                    className="nav-link"
                                    role="button"
                                    href="/#"
                                    onClick={() => authStore.signOut()}
                                >
                                    {authStore.user.email}
                                </a>
                            </Navbar.Text>
                        </Navbar.Collapse>
                    </Navbar>
                    <Route
                        exact
                        path="/"
                        render={(props) => (
                            <Home
                                propertyStore={propertyStore}
                                favoriteStore={favoriteStore}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/feed"
                        render={(props) => (
                            <Feed propertyStore={propertyStore} />
                        )}
                    />
                    <Route
                        path={"/property/:propertyId"}
                        render={(props) => (
                            <Detail
                                propertyId={props.match.params.propertyId}
                                propertyStore={propertyStore}
                                favoriteStore={favoriteStore}
                            />
                        )}
                    />
                </Router>
            </div>
        );
    }
}

export default App;
