import { observable } from "mobx";
import PropertyStore from "../stores/PropertyStore";
import { IGeo, IZillowObj, IZillowObjSimple } from "../interfaces/data";
import JournalEntryModel from "./JournalEntryModel";

export default class PropertyModel {
    store: PropertyStore;
    id: string;
    @observable openingBid: number;
    @observable address: string;
    @observable auctionDate: Date;
    @observable saleDate: string;
    @observable isStale: boolean;
    @observable saleTime: string;
    @observable saleStatus: string;
    @observable city: string;
    @observable state: string;
    @observable zip: string;
    @observable county: string;
    @observable source: string;
    @observable geo: Array<IGeo>;
    @observable zillow: Array<IZillowObjSimple>;
    @observable journalEntries: Array<JournalEntryModel> = [];
    @observable lastUpdateDate: string;

    constructor(store: PropertyStore, data: { [field: string]: any }) {
        this.store = store;
        this.id = data.id;
        this.openingBid = data.openingBid;
        this.saleStatus = data.status;
        this.saleDate = data.saleDate;
        this.isStale = data.isStale;
        this.saleTime = data.saleTime;
        this.auctionDate = data.auctionDate.toDate();

        this.address = data.address;
        this.city = data.city;
        this.state = data.state;
        this.zip = data.zip;
        this.county = data.county;

        this.geo = data.geo;
        this.zillow = data.zillow;

        this.source = data.source;
        this.lastUpdateDate = data.lastUpdateDate.toDate();

        this.zillow = data.zillow.map((zdata: IZillowObj) => {
            return {
                zestimate: zdata.zestimate
                    ? zdata.zestimate[0].amount[0]["_"]
                    : 0,
                rentzestimate: zdata.rentzestimate
                    ? zdata.rentzestimate[0].amount[0]["_"]
                    : 0,
                link: zdata.links[0].homedetails[0],
                address: zdata.address[0].street[0],
            };
        });
    }

    getFullAddress() {
        return [this.address, this.city, this.state, this.zip]
            .filter((value) => !!value)
            .join(", ");
    }

    toJS() {
        return {};
    }

    // static fromJS(store, object) {
    //     return new PropertyModel(store, object.id, object.title, object.completed);
    // }
}
