import "./PropertyToolbar.css";

import {
    ButtonToolbar,
    InputGroup,
    ToggleButton,
    ToggleButtonGroup,
} from "react-bootstrap";
import React, { Component } from "react";
import { FILTER_OPTIONS } from "../enums";
import { IPropertyToolbarProps } from "../interfaces/component-props";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

class PropertyToolbar extends Component<IPropertyToolbarProps, any> {
    static propTypes = {
        propertyStore: PropTypes.object.isRequired,
    };

    toggleFavoritesFilter(value: boolean) {
        this.props.propertyStore.showFavoritesOnly = value;
    }

    toggleHideStaleProperties(value: boolean) {
        this.props.propertyStore.hideStaleProperties = value;
    }

    setFilterText(value: string) {
        this.props.propertyStore.filterText = value;
    }

    render() {
        const { propertyStore } = this.props;

        return (
            <ButtonToolbar className="PropertyToolbar properties-toolbar justify-content-between align-items-center">
                <InputGroup className="align-items-center form-stuff">
                    <Form.Control
                        type="text"
                        placeholder="Filter by &hellip;"
                        value={propertyStore.filterText}
                        onChange={(evt) =>
                            this.setFilterText(evt.currentTarget.value)
                        }
                    />
                    <Form.Check
                        type="checkbox"
                        label="Favorites"
                        checked={propertyStore.showFavoritesOnly}
                        onChange={(evt) =>
                            this.toggleFavoritesFilter(
                                evt.currentTarget.checked
                            )
                        }
                    />{" "}
                    <Form.Check
                        type="checkbox"
                        label="Hide Stale"
                        checked={propertyStore.hideStaleProperties}
                        onChange={(evt) =>
                            this.toggleHideStaleProperties(
                                evt.currentTarget.checked
                            )
                        }
                    />
                </InputGroup>
                <ToggleButtonGroup
                    type="radio"
                    name="options"
                    defaultValue={propertyStore.dbFilter}
                    onChange={(value) => propertyStore.setFilter(value)}
                >
                    {FILTER_OPTIONS.map((option, idx) => (
                        <ToggleButton
                            key={idx}
                            variant="light"
                            value={option.value}
                        >
                            {option.label}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </ButtonToolbar>
        );
    }
}

export default PropertyToolbar;
