import React, { Component } from "react";
import { UpdatesFeedProps } from "../interfaces/component-props";
import JournalEntryModel from "../models/JournalEntryModel";
import { isSameDay, format, add } from "date-fns";

const alphabetically = (caseInsensitive = false) => {
    return (a, b) => {
        const aValue = caseInsensitive ? a.toLowerCase() : a;
        const bValue = caseInsensitive ? b.toLowerCase() : b;
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
    };
};

class UpdatesFeed extends Component<UpdatesFeedProps, any> {
    static defaultProps = {
        onClickProperty: () => void 0,
        isSingleProperty: false,
    };

    lastheadingDate: Date;

    heading(entry: JournalEntryModel) {
        if (!isSameDay(this.lastheadingDate, entry.updateDate)) {
            this.lastheadingDate = entry.updateDate;
            return (
                <h3 title={format(entry.updateDate, "MMM d, yyyy")}>
                    {format(entry.updateDate, "MMM d, yyyy")}
                </h3>
            );
        }
        return void 0;
    }

    renderLineItems(
        entry: JournalEntryModel,
        idx: number,
        withPropertyAddress: boolean = true
    ) {
        const lineItems = (
            <ul>
                {Object.keys(entry.changes)
                    .sort(alphabetically(true))
                    .map((key, idx) => {
                        let prevValue = "";

                        const numEntries = entry.changes[key].length;

                        prevValue =
                            numEntries === 2 &&
                            typeof entry.changes[key][0] !== "object"
                                ? String(entry.changes[key][0])
                                : "";

                        const newValue =
                            numEntries > 0 &&
                            typeof entry.changes[key][numEntries - 1] !==
                                "object"
                                ? String(entry.changes[key][numEntries - 1])
                                : "";

                        return (
                            <li key={`journal_${idx}`}>
                                <strong>{key}</strong> changed from "{prevValue}
                                " to "{newValue}
                                ".
                            </li>
                        );
                    })}
            </ul>
        );

        if (withPropertyAddress) {
            return (
                <ul key={`changes_${idx}`}>
                    <li>
                        <button
                            type="button"
                            className="btn btn-link"
                            onClick={() =>
                                this.props.onClickProperty(entry.property)
                            }
                        >
                            {entry.property.address}, {entry.property.city},{" "}
                            {entry.property.state}
                        </button>
                    </li>
                    {lineItems}
                </ul>
            );
        } else {
            return lineItems;
        }
    }

    render() {
        this.lastheadingDate = add(new Date(), {
            days: 7,
        });

        return (
            <ul>
                {this.props.journalEntries.map((entry, idx) => (
                    <React.Fragment key={`grp_${idx}`}>
                        {this.heading(entry)}
                        {this.renderLineItems(
                            entry,
                            idx,
                            !this.props.isSingleProperty
                        )}
                    </React.Fragment>
                ))}
            </ul>
        );
    }
}

export default UpdatesFeed;
