import React, { Component } from "react";

import { IDetailProps } from "../interfaces/component-props";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import PropertyDetails from "./PropertyDetails";

@observer
class Detail extends Component<IDetailProps, any> {
    static propTypes = {
        propertyStore: PropTypes.object.isRequired,
        propertyId: PropTypes.string.isRequired,
        favoriteStore: PropTypes.object.isRequired,
    };

    render() {
        const { propertyStore, favoriteStore } = this.props;

        return (
            <PropertyDetails
                propertyStore={propertyStore}
                favoriteStore={favoriteStore}
                propertyId={this.props.propertyId}
            />
        );
    }
}

export default Detail;
