import { computed, observable } from "mobx";
import JournalEntryStore from "../stores/JournalEntryStore";
import PropertyModel from "./PropertyModel";

export default class JournalEntryModel {
    store: JournalEntryStore;

    @observable property: PropertyModel;
    propertyId: string;
    @observable updateDate: Date;
    @observable changes: object;

    constructor(store: JournalEntryStore, data: { [field: string]: any }) {
        this.store = store;
        this.propertyId = data.propertyId;
        this.property = data.property;
        this.updateDate = data["updateDate"].toDate();
        this.changes = data["changes"];
    }

    @computed get address(): string {
        return this.property.address;
    }

    toJS() {
        return {};
    }

    // static fromJS(store, object) {
    //     return new PropertyModel(store, object.id, object.title, object.completed);
    // }
}
