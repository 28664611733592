import "./PropertyDetails.css";
import { observer } from "mobx-react";
import React, { Component } from "react";
import { IPropertyDetailsProps } from "../interfaces/component-props";
import PropTypes from "prop-types";
import { TABLE_COLUMNS } from "../enums";
import PropertyMap from "./PropertyMap";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import PropertyModel from "../models/PropertyModel";
import { withRouter } from "react-router-dom";
import UpdatesFeed from "./UpdatesFeed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHeart as faHeart_solid } from "@fortawesome/free-solid-svg-icons";
import {
    faCopy,
    faHeart as faHeart_light,
    faMap,
} from "@fortawesome/free-regular-svg-icons";
import { IGeo } from "../interfaces/data";
import { isArray } from "lodash-es";

const streetViewUrl = (geoArray: Array<IGeo>) => {
    if (!isArray(geoArray) || geoArray.length < 1) {
        return "/#";
    }

    const geo: IGeo = geoArray[geoArray.length - 1];
    return `http://maps.google.com/maps?q=&layer=c&cbll=${geo.geometry.location.lat},${geo.geometry.location.lng}`;
};

const copyAddress = (property: PropertyModel) => {
    if (!property) {
        return;
    }

    return navigator.clipboard.writeText(property.getFullAddress());
};

const copyAddressAndGo = (property: PropertyModel, url: string) => {
    copyAddress(property).then(() => {
        window.open(url, "_blank");
    });
};

@observer
class PropertyDetails extends Component<IPropertyDetailsProps, any> {
    static propTypes = {
        propertyStore: PropTypes.object.isRequired,
        favoriteStore: PropTypes.object.isRequired,
        propertyId: PropTypes.string.isRequired,
    };

    componentDidMount(): void {
        this.props.propertyStore.fetchProperty(this.props.propertyId);
    }

    onSelectProperty(property: PropertyModel) {
        this.props.propertyStore.fetchProperty(property.id);
        this.props.history.push(`/property/${property.id}`);
    }

    toggleFavorite() {
        this.props.favoriteStore.toggleFavorite(this.props.propertyId);
    }

    render() {
        const { propertyStore, favoriteStore } = this.props;
        const property = propertyStore.property;
        if (!property) {
            return <div></div>;
        }

        return (
            <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2" onClick={() => copyAddress(property)}>
                        {property.address}{" "}
                        <small className="text-muted text-uppercase font-weight-light">
                            {property.city}, {property.state}
                        </small>
                    </h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group mr-2">
                            <button
                                type="button"
                                className="btn btn-sm btn-outline-secondary"
                                onClick={() => this.toggleFavorite()}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        favoriteStore.favoritesByPropertyId[
                                            property.id
                                        ]
                                            ? faHeart_solid
                                            : faHeart_light
                                    }
                                />
                            </button>
                        </div>
                    </div>
                </div>

                <PropertyMap
                    properties={propertyStore.filteredProperties}
                    selectedProperty={property}
                    onClickMarker={(property) =>
                        this.onSelectProperty(property)
                    }
                />
                <ButtonToolbar className="properties-toolbar justify-content-between">
                    <div>&nbsp;</div>
                </ButtonToolbar>

                <div className="table-responsive">
                    <table
                        cellPadding={0}
                        cellSpacing={0}
                        className="table table-sm table-striped"
                    >
                        <tbody>
                            <tr>
                                <td>Address</td>
                                <td>
                                    {property.getFullAddress()}
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-link"
                                        onClick={() => copyAddress(property)}
                                    >
                                        <FontAwesomeIcon icon={faCopy} />
                                    </button>
                                </td>
                            </tr>
                            {TABLE_COLUMNS.filter(
                                (col) =>
                                    [
                                        "address",
                                        "county",
                                        "city",
                                        "state",
                                    ].indexOf(col.key) < 0,
                            ).map((col, idx) => (
                                <tr key={idx}>
                                    <td>{col.label}</td>
                                    <td>
                                        {col.detailedCellRenderer(
                                            property,
                                            col.key,
                                        )}
                                    </td>
                                </tr>
                            ))}

                            <tr>
                                <td>Links</td>
                                <td>
                                    <a
                                        href="/#"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();
                                            copyAddressAndGo(
                                                property,
                                                "https://zillow.com",
                                            );
                                        }}
                                    >
                                        Zillow
                                    </a>
                                    &nbsp;|&nbsp;
                                    <a
                                        href="/#"
                                        onClick={(evt) => {
                                            evt.stopPropagation();
                                            evt.preventDefault();

                                            copyAddressAndGo(
                                                property,
                                                "https://redfin.com",
                                            );
                                        }}
                                    >
                                        Redfin
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>Google Street View</td>
                                <td>
                                    <a
                                        href={streetViewUrl(property.geo)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FontAwesomeIcon icon={faMap} />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h2>Updates Feed</h2>
                <UpdatesFeed
                    journalEntries={property.journalEntries}
                    isSingleProperty={true}
                />
            </div>
        );
    }
}

export default withRouter(PropertyDetails);
