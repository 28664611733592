import React, { Component } from "react";
import { IHomeProps } from "../interfaces/component-props";
import PropertiesTable from "./PropertiesTable";
import PropertyToolbar from "./PropertyToolbar";
import PropertyMap from "./PropertyMap";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import PropertyModel from "../models/PropertyModel";
import RefreshButton from "./RefreshButton";

@observer
class Home extends Component<IHomeProps, any> {
    state = {
        selectedTab: "properties",
    };

    static propTypes = {
        propertyStore: PropTypes.object.isRequired,
        favoriteStore: PropTypes.object.isRequired,
    };

    componentDidMount(): void {
        this.props.propertyStore.fetchProperties();
    }

    onSelectProperty(property: PropertyModel) {
        this.props.history.push(`/property/${property.id}`);
    }

    render() {
        const { propertyStore, favoriteStore } = this.props;

        return (
            <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Real Estate Properties</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group mr-2">
                            <RefreshButton
                                onSync={() => propertyStore.synchronize()}
                            />
                        </div>
                    </div>
                </div>
                <PropertyMap
                    properties={propertyStore.filteredProperties}
                    onClickMarker={(property) =>
                        this.onSelectProperty(property)
                    }
                />

                <PropertyToolbar propertyStore={propertyStore} />
                <div className="table-responsive">
                    <PropertiesTable
                        properties={propertyStore.filteredProperties}
                        favoriteStore={favoriteStore}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(Home);
