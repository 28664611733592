import React, { Component } from "react";
import { IToolbarRefreshBtnProps } from "../interfaces/component-props";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

class RefreshButton extends Component<IToolbarRefreshBtnProps, any> {
    static propTypes = {
        onSync: PropTypes.func.isRequired,
    };

    state = {
        isLoading: false,
    };

    handleClick = () => {
        this.setState({ isLoading: true });

        this.props.onSync().then(() => {
            this.setState({ isLoading: false });
        });
    };

    render() {
        return (
            <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                disabled={this.state.isLoading}
                onClick={!this.state.isLoading ? this.handleClick : null}
            >
                {this.state.isLoading ? (
                    <FontAwesomeIcon icon={faSync} spin />
                ) : (
                    <FontAwesomeIcon icon={faSync} />
                )}
            </button>
        );
    }
}

export default RefreshButton;
