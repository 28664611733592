import "./SignIn.css";

import React, { Component } from "react";
import { SignInProps } from "../interfaces/component-props";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import classNames from "classnames";
import Spinner from "react-bootstrap/Spinner";

class SignIn extends Component<SignInProps, any> {
    private _isMounted: boolean = false;

    state = {
        username: "",
        password: "",
        errorMessage: "",
        signingIn: false,
    };

    componentDidMount(): void {
        this._isMounted = true;
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    handleChange(fieldName: string, value: any) {
        const newState = {
            errorMessage: "",
        };
        newState[fieldName] = value;
        this.setState(newState);
    }

    setState(...args) {
        if (this._isMounted) {
            super.setState.apply(this, args);
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
            signingIn: true,
        });

        this.props.authStore
            .signIn(this.state.username, this.state.password)
            .catch((err) => {
                this.setState({
                    errorMessage: err.message,
                });
                console.log(err);
            })
            .finally(() => {
                this.setState({
                    username: "",
                    password: "",
                    signingIn: false,
                });
            });
    }

    render() {
        return (
            <div className="SignIn text-center">
                <Form
                    className="sign-in"
                    onSubmit={(event) => this.handleSubmit(event)}
                >
                    <h1 className="h3 mb-3 font-weight-normal">
                        Please sign in
                    </h1>
                    <Form.Label srOnly={true}>Email address</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Email address"
                        value={this.state.username}
                        onChange={(evt) =>
                            this.handleChange("username", evt.target.value)
                        }
                        minLength={4}
                        required
                    />

                    <Form.Label srOnly={true}>Password</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={(evt) =>
                            this.handleChange("password", evt.target.value)
                        }
                        minLength={4}
                        required
                    />

                    <div
                        className={classNames("alert", "alert-warning", {
                            "d-none": this.state.errorMessage.length === 0,
                        })}
                        role="alert"
                    >
                        {this.state.errorMessage}
                    </div>

                    <Button
                        type="submit"
                        className="btn btn-lg btn-primary btn-block btn-sign-in"
                        disabled={this.state.signingIn}
                    >
                        {this.state.signingIn ? (
                            <React.Fragment>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span>Signing In&hellip;</span>
                            </React.Fragment>
                        ) : (
                            "Sign in"
                        )}
                    </Button>
                </Form>
            </div>
        );
    }
}

export default SignIn;
