/* global google */
import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { IPropetyMapProps } from "../interfaces/component-props";
import PropertyModel from "../models/PropertyModel";
import { observer } from "mobx-react";

@observer
class PropertyMap extends Component<IPropetyMapProps, any> {
    map: google.maps.Map;
    markers: Object;

    static defaultProps = {
        center: {
            lat: 40.447714,
            lng: -111.894538,
        },
        zoom: 10,
        properties: [],
        selectedProperty: void 0,
        onClickMarker: () => void 0,
    };

    constructor(props: IPropetyMapProps) {
        super(props);
        this.markers = {};
    }

    handleApiLoaded(map: google.maps.Map) {
        this.map = map;
        this.updateMarkers();
    }

    updateMarkers() {
        this.props.properties.forEach((property: PropertyModel) => {
            if (property.geo.length > 0) {
                let marker: google.maps.Marker;

                if (!this.markers[property.id]) {
                    const coords = property.geo[0].geometry.location;
                    const latLng = new google.maps.LatLng(
                        coords.lat,
                        coords.lng,
                    );
                    marker = new google.maps.Marker({
                        position: latLng,
                        map: this.map,
                    });
                    marker.addListener("click", (evt) =>
                        this.handleClickMarker(property),
                    );
                    this.markers[property.id] = marker;
                } else {
                    marker = this.markers[property.id];
                    marker.setMap(this.map);
                }

                marker.setAnimation(
                    this.props.selectedProperty &&
                        property.id === this.props.selectedProperty.id
                        ? google.maps.Animation.BOUNCE
                        : void 0,
                );
            }
        });
    }

    componentDidUpdate(prevProps: IPropetyMapProps) {
        const curIds = this.props.properties.map((property) => property.id);

        for (const id in this.markers) {
            if (curIds.indexOf(id) === -1) {
                this.markers[id].setMap(void 0);
            }
        }

        this.updateMarkers();
    }

    handleClickMarker(data) {
        this.props.onClickMarker(data);
    }

    render() {
        const { selectedProperty } = this.props;
        const center = selectedProperty
            ? selectedProperty.geo[0].geometry.location
            : this.props.center;

        return (
            <div style={{ height: "25vh", width: "100%" }}>
                <div style={{ display: "none" }}>
                    {this.props.properties.length}
                </div>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: "AIzaSyAVOuCJhgtVxXpLGqJhARKl35P9db9ERg0",
                    }}
                    center={center}
                    defaultZoom={this.props.zoom}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={({ map }) => this.handleApiLoaded(map)}
                ></GoogleMapReact>
            </div>
        );
    }
}

export default PropertyMap;
