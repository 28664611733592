import React, { ReactElement } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import PropertyStore from "./stores/PropertyStore";
import AuthStore from "./stores/AuthStore";
import { reaction, when } from "mobx";
import FavoriteStore from "./stores/FavoriteStore";
import SignIn from "./components/SignIn";

const authStore = new AuthStore();

when(
    () => authStore.hasInitialized,
    () => {
        reaction(
            () => authStore.isLoggedIn,
            (isLoggedIn) => {
                let app: ReactElement;

                if (isLoggedIn) {
                    const favoriteStore = new FavoriteStore(authStore);
                    const propertyStore = new PropertyStore(
                        authStore,
                        favoriteStore,
                    );
                    app = (
                        <App
                            propertyStore={propertyStore}
                            authStore={authStore}
                            favoriteStore={favoriteStore}
                        />
                    );
                } else {
                    app = <SignIn authStore={authStore} />;
                }

                ReactDOM.render(app, document.getElementById("root"));
            },
            {
                fireImmediately: true,
            },
        );
    },
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
