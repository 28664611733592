import { IListFilter } from "./interfaces/component-props";
import { format } from "date-fns";
// @ts-ignore
import { SOURCE_URLS } from "./shared/constants";

const NOW: Date = new Date();

function byDate(key: string, asc: boolean = true): (a: any, b: any) => number {
    return function (a, b) {
        return asc ? a[key] - b[key] : b[key] - a[key];
    };
}

function formatCurrency(value) {
    if (typeof value === "string") {
        value = parseFloat(value.replace(/,/g, ""));
    }

    if (isNaN(value) || value === 0) return "";

    return (
        "$" +
        new Intl.NumberFormat("en-US", { maximumSignificantDigits: 2 }).format(
            value
        )
    );
}

const FILTER_OPTIONS: Array<IListFilter> = [
    {
        label: "Upcoming",
        value: "upcoming",
        firestoreFilter: { fieldPath: "auctionDate", opStr: ">=", value: NOW },
        sortFunction: byDate("auctionDate"),
    },
    {
        label: "Past",
        value: "past",
        firestoreFilter: { fieldPath: "auctionDate", opStr: "<", value: NOW },
        sortFunction: byDate("auctionDate", false),
    },
];

const TABLE_COLUMNS = [
    {
        label: "Address",
        key: "address",
        cellRenderer: (data, key, favoriteStore) => {
            const link = `<a href="/property/${data.id}">${data[key]}</a>`;

            return [
                link,
                favoriteStore.favoritesByPropertyId[data.id] ? "♥️" : undefined,
                data.isStale ? "👻" : undefined,
            ]
                .filter((item) => typeof item !== "undefined")
                .join(" ");
        },
        detailedCellRenderer: (data, key) => data[key],
    },
    {
        label: "City",
        key: "city",
        cellRenderer: (data, key) => data[key],
        detailedCellRenderer: (data, key) => data[key],
    },
    {
        label: "State",
        key: "state",
        cellRenderer: (data, key) => data[key],
        detailedCellRenderer: (data, key) => data[key],
    },
    {
        label: "Auction Date",
        key: "auctionDate",
        cellRenderer: (data, key) => format(data[key], "MMM d"),
        detailedCellRenderer: (data, key) =>
            format(data[key], "MMM d yyyy - h:mm a"),
    },
    {
        label: "Opening Bid",
        key: "openingBid",
        cellRenderer: (data, key) => formatCurrency(data[key]),
        detailedCellRenderer: (data, key) => data[key],
    },
    {
        label: "Status",
        key: "saleStatus",
        cellRenderer: (data, key) => data[key],
        detailedCellRenderer: (data, key) => data[key],
    },
    {
        label: "Source",
        key: "source",
        cellRenderer: (data, key) => {
            const url = SOURCE_URLS[data[key]];

            return url ? `<a href="${url}">${data[key]}</a>` : data[key];
        },
        detailedCellRenderer: (data, key) => data[key],
    },
];

function findFilterByValue(value): IListFilter {
    return FILTER_OPTIONS.find((filter) => filter.value === value);
}

export { FILTER_OPTIONS, TABLE_COLUMNS, findFilterByValue, formatCurrency };
