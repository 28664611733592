import { action, computed, flow, observable, reaction } from "mobx";
import PropertyModel from "../models/PropertyModel";
import Fiyabase from "../Fiyabase";
import { findFilterByValue } from "../enums";
import { IListFilter } from "../interfaces/component-props";
import JournalEntryStore from "./JournalEntryStore";
import JournalEntryModel from "../models/JournalEntryModel";
import AuthStore from "./AuthStore";
import FavoriteStore from "./FavoriteStore";
import store from "store2";
import {
    getFirestore,
    collection,
    where,
    orderBy,
    query,
    getDocs,
    limit,
} from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";

const db = getFirestore(Fiyabase());
const functions = getFunctions(Fiyabase());
const syncProperties = httpsCallable(functions, "sync");

export default class PropertyStore {
    private _showFavoritesOnly: boolean =
        localStorage.getItem("showFavoritesOnly") === "true" || false;

    private _hideStaleProperties: boolean =
        localStorage.getItem("hideStaleProperties") === "true" || false;

    private _filterText: string = "";

    journalEntryStore: JournalEntryStore;
    authStore: AuthStore;
    favoriteStore: FavoriteStore;

    @observable property: PropertyModel;
    @observable properties: Array<PropertyModel> = [];

    @observable filterFunction: (
        value: any,
        index: number,
        array: any[]
    ) => any;
    @observable dbFilter: string = store.get("viewFilter") || "upcoming";

    constructor(authStore: AuthStore, favoriteStore: FavoriteStore) {
        this.journalEntryStore = new JournalEntryStore();
        this.authStore = authStore;
        this.favoriteStore = favoriteStore;
        this.updateFilter();

        reaction(
            () => Object.keys(this.favoriteStore.favoritesByPropertyId).length,
            (numFavorites) => this.updateFilter()
        );
    }

    @computed
    set showFavoritesOnly(value: boolean) {
        this._showFavoritesOnly = value;
        localStorage.setItem("showFavoritesOnly", String(value));
        this.updateFilter();
    }

    get showFavoritesOnly(): boolean {
        return this._showFavoritesOnly;
    }

    @computed
    set hideStaleProperties(value: boolean) {
        this._hideStaleProperties = value;
        localStorage.setItem("hideStaleProperties", String(value));
        this.updateFilter();
    }

    get hideStaleProperties(): boolean {
        return this._hideStaleProperties;
    }

    @computed
    set filterText(value: string) {
        this._filterText = value;
        this.updateFilter();
    }

    get filterText(): string {
        return this._filterText;
    }

    @action
    fetchProperties = flow(function* () {
        const self = this;
        const filterObj: IListFilter = findFilterByValue(this.dbFilter);
        const fireStoreFilter = filterObj.firestoreFilter;

        try {
            const q =
                typeof fireStoreFilter === "undefined"
                    ? query(collection(db, "properties"))
                    : query(
                          collection(db, "properties"),
                          where(
                              fireStoreFilter.fieldPath,
                              fireStoreFilter.opStr,
                              fireStoreFilter.value
                          )
                      );
            const properties = yield getDocs(q);

            self.properties = properties.docs
                .map((property) => new PropertyModel(this, property.data()))
                .sort(filterObj.sortFunction);
            self.property = void 0;
        } catch (err) {
            console.log("ERR", err);
        }
    });

    @action
    setFilter(filterValue: string) {
        this.dbFilter = filterValue;
        store.set("viewFilter", filterValue);
        this.fetchProperties();
    }

    @action
    synchronize(): Promise<any> {
        return syncProperties()
            .then((data) => {
                this.fetchProperties();
            })
            .catch((err) => console.log(err));
    }

    updateFilter() {
        const favoritePropertyIds = Object.keys(
            this.favoriteStore.favoritesByPropertyId
        );

        const filterText = this._filterText.toLowerCase();
        const showFavoritesOnly = this._showFavoritesOnly;
        const hideStaleProperties = this._hideStaleProperties;

        this.filterFunction = (property) => {
            let show: boolean = true;

            if (showFavoritesOnly) {
                show = show && favoritePropertyIds.includes(property.id);
            }

            if (hideStaleProperties) {
                show = show && !property.isStale;
            }

            if (filterText !== "") {
                let textShow = false;

                for (const key of ["address", "city", "saleStatus", "source"]) {
                    const keyValue =
                        typeof property[key] === "string"
                            ? property[key].toLowerCase()
                            : "";
                    textShow = textShow || keyValue.includes(filterText);
                }

                show = show && textShow;
            }

            return show;
        };
    }

    @computed get filteredProperties() {
        return this.properties.filter(this.filterFunction);
    }

    toJS() {
        // return this.properties.map(todo => todo.toJS());
    }

    @action
    fetchProperty = flow(function* (propertyId: string) {
        const self = this;
        try {
            const properties = yield getDocs(
                query(
                    collection(db, "properties"),
                    where("id", "==", propertyId),
                    limit(1)
                )
            );

            const entries = yield getDocs(
                query(
                    collection(db, "journalentries"),
                    where("propertyId", "==", propertyId),
                    orderBy("updateDate", "desc")
                )
            );

            if (properties.docs.length > 0) {
                const property = new PropertyModel(
                    this,
                    properties.docs[0].data()
                );

                property.journalEntries = entries.docs.map(
                    (entry) =>
                        new JournalEntryModel(
                            self.journalEntryStore,
                            entry.data()
                        )
                );
                self.property = property;
                if (self.properties.length === 0) {
                    self.properties = [property];
                }
            }
        } catch (err) {
            console.log("ERR", err);
        }
    });

    fetchAllJournalEntries() {
        this.journalEntryStore.fetchJournalEntries();
    }

    static fromJS(array) {}
}
