import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import { FirebaseApp } from "@firebase/app";

const DEFAULT_CONFIG = {
    apiKey: "AIzaSyDSsMhMRc1bpyBmtZpamPva2souPznquw4",
    authDomain: "re-king.firebaseapp.com",
    databaseURL: "https://re-king.firebaseio.com",
    projectId: "re-king",
    storageBucket: "re-king.appspot.com",
    messagingSenderId: "802041440317",
    appId: "1:802041440317:web:b36ce12b2ddd077b7a6254",
};

let app: FirebaseApp;

function Fiyabase(config = DEFAULT_CONFIG): FirebaseApp {
    if (!app) {
        app = firebase.initializeApp(config);
    }
    return app;
}

export default Fiyabase;
