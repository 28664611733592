import React, { Component } from "react";
import {
    IPropertiesTableProps,
    IPropertyRowProps,
} from "../interfaces/component-props";
import { observer } from "mobx-react";
import { formatCurrency, TABLE_COLUMNS } from "../enums";

class PropertiesTableHeader extends Component {
    render() {
        return (
            <tr>
                {TABLE_COLUMNS.map((col, idx) => (
                    <th key={idx}>{col.label}</th>
                ))}
            </tr>
        );
    }
}

@observer
class PropertyRow extends Component<IPropertyRowProps, any> {
    static defaultProps = {
        onClick: () => void 0,
    };

    render() {
        const { favoriteStore } = this.props;

        return (
            <tr onClick={() => this.props.onClick()}>
                {TABLE_COLUMNS.map((col, idx) => {
                    return (
                        <td
                            key={idx}
                            title={col.detailedCellRenderer(
                                this.props.data,
                                col.key
                            )}
                            dangerouslySetInnerHTML={{
                                __html: col.cellRenderer(
                                    this.props.data,
                                    col.key,
                                    favoriteStore
                                ),
                            }}
                        ></td>
                    );
                })}

                <React.Fragment>
                    <td>
                        {this.props.data.zillow.map((z, idx) => (
                            <div key={idx}>{formatCurrency(z.zestimate)}</div>
                        ))}
                    </td>
                    <td>
                        {this.props.data.zillow.map((z, idx) => (
                            <div key={idx}>
                                {formatCurrency(z.rentzestimate)}
                            </div>
                        ))}
                    </td>
                    <td>
                        {this.props.data.zillow.map((z, idx) => (
                            <div key={idx}>
                                <a
                                    href={z.link}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {z.address}
                                </a>
                            </div>
                        ))}
                    </td>
                </React.Fragment>
            </tr>
        );
    }
}

@observer
export default class PropertiesTable extends Component<
    IPropertiesTableProps,
    any
> {
    static defaultProps = {
        onClickRow: () => void 0,
    };

    render() {
        const { favoriteStore } = this.props;
        return (
            <table
                cellPadding={0}
                cellSpacing={0}
                className="table table-sm table-striped table-hover"
            >
                <thead>
                    <PropertiesTableHeader />
                </thead>
                <tbody>
                    {this.props.properties.map((property) => (
                        <PropertyRow
                            key={property.id}
                            data={property}
                            favoriteStore={favoriteStore}
                            onClick={() => this.props.onClickRow(property)}
                        />
                    ))}
                </tbody>
            </table>
        );
    }
}
