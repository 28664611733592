import { action, flow, observable } from "mobx";
import Fiyabase from "../Fiyabase";
import { getAuth, signInWithEmailAndPassword, User } from "firebase/auth";

const auth = getAuth(Fiyabase());

export default class AuthStore {
    @observable hasInitialized: boolean = false;
    @observable isLoggedIn: boolean;
    @observable user: User;

    constructor() {
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.user = user;
                this.isLoggedIn = true;
            } else {
                this.isLoggedIn = false;
            }
            this.hasInitialized = true;
        });
    }

    @action
    signIn = flow(function* (userIdentifierEmail: string, password: string) {
        if (auth.currentUser) {
            yield auth.signOut();
        }

        return signInWithEmailAndPassword(auth, userIdentifierEmail, password);
    });

    @action
    signOut() {
        this.isLoggedIn = false;
        this.user = void 0;
        return auth.signOut();
    }

    toJS() {}

    static fromJS(array) {}
}
