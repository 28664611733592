import { action, flow, observable } from "mobx";
import Fiyabase from "../Fiyabase";
import AuthStore from "./AuthStore";
import { keyBy } from "lodash-es";
import {
    getFirestore,
    collection,
    query,
    getDocs,
    where,
    doc,
    deleteDoc,
    addDoc,
} from "firebase/firestore";

const db = getFirestore(Fiyabase());

export default class FavoriteStore {
    authStore: AuthStore;

    @observable favoritesByPropertyId: { [key: string]: any } = {};

    constructor(authStore: AuthStore) {
        this.authStore = authStore;
        this.fetchFavorites();
    }

    @action
    fetchFavorites = flow(function* () {
        const self = this;
        try {
            const dbfavorites = yield getDocs(
                query(
                    collection(db, "favorites"),
                    where("userId", "==", this.authStore.user.uid),
                ),
            );

            const favorites = dbfavorites.docs.map((favorite) => {
                return {
                    id: favorite.id,
                    ...favorite.data(),
                };
            });
            self.favoritesByPropertyId = keyBy(favorites, "propertyId");
        } catch (err) {
            console.log("ERR", err);
        }
    });

    @action
    toggleFavorite(propertyId: string) {
        const favorite = this.favoritesByPropertyId[propertyId];

        if (favorite) {
            delete this.favoritesByPropertyId[propertyId];
            deleteDoc(doc(db, "favorites", favorite.id));
        } else {
            const newFavorite = {
                propertyId,
                userId: this.authStore.user.uid,
            };

            addDoc(collection(db, "favorites"), newFavorite)
                .then((docRef) => {
                    newFavorite["id"] = docRef.id;
                    this.favoritesByPropertyId[propertyId] = newFavorite;
                })
                .catch((error) => {
                    console.error("Error adding favorite: ", error);
                });
        }
    }

    toJS() {}

    static fromJS(array) {}
}
